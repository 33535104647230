<template>
  <el-dialog title="取车" :visible.sync="visible" width="460px">
    <div class="cardTop">
      <img :src="base.carLogo" alt="" />
      <div class="pdtb10">{{ base.rentalModelName }}</div>
    </div>
    <div class="contentBox">
      <div class="indent10">车辆配置</div>
      <div class="infoBox mt20">
        <div class="borderR">
          <div class="sing">{{ carInfo.gearbox }}</div>
          <div>变速箱</div>
        </div>
        <div class="borderR">
          <div class="sing">{{ carInfo.fuelTankCapacity }}L</div>
          <div>油箱容量</div>
        </div>
        <div>
          <div class="sing">
            {{ carInfo.displacement
            }}{{ carInfo.intakeFormCode == "WLZY" ? "T" : "L" }}
          </div>
          <div>排量</div>
        </div>
        <div class="borderR borderT">
          <div class="sing">{{ carInfo.seatNum }}</div>
          <div>座位数</div>
        </div>
        <div class="borderR borderT">
          <div class="sing">{{ carInfo.intakeForm }}</div>
          <div>进气</div>
        </div>
        <div class="borderT">
          <div class="sing">
            {{ carInfo.reversingRadar == 1 ? "有" : "无" }}
          </div>
          <div>倒车雷达</div>
        </div>
      </div>
    </div>

    <div class="mt20 insertBox">
      <label>取车车辆：</label>
      <el-select v-model="base.vehicleId" size="small" @change="checkCar">
        <el-option
          v-for="item in carList"
          :key="item.vehicleId"
          :value="item.vehicleId"
          :label="
            item.brand +
            '-' +
            item.vehiclePlateNumber +
            item.vehiclePlateColor +
            '-' +
            item.seatNumber +
            '座'
          "
        ></el-option>
      </el-select>
    </div>
    <div class="mt20 insertBox">
      <label>取车时里程表值：</label>
      <el-input-number
        v-model="base.startDriveDistance"
        :min="1"
        :controls="false"
        size="small"
        placeholder="请输入"
        class="number-text-l"
      />
    </div>

    <div class="mt20 text-r">
      <el-button size="small" :loading="loading" @click="handleCancel"
        >取消</el-button
      >
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="handleSubmit"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { getVechicleList, takeCarPickUp } from "@/api/lib/api";
export default {
  data() {
    return {
      visible: false,
      loading: false,
      base: {},
      carInfo: {},
      carList: [],
    };
  },
  methods: {
    show(row = {}) {
      this.visible = true;
      this.loading = false;
      this.base = { ...row };
      this.carInfo = { ...row.rentalVehicleModel };
      let orderCompanyId = row.companyId;
      let vehicleModelTypeCode = row.rentalVehicleModel.vehicleModelTypeId;
      this.getCarList({ orderCompanyId, vehicleModelTypeCode });
    },
    //获取可选车辆列表
    getCarList(ps) {
      getVechicleList(ps).then((res) => {
        if (res.code == "SUCCESS") {
          let list = res.data || [];
          this.carList = list.map((item) => {
            let {
              id: vehicleId,
              brand,
              seatNumber,
              licensePlateColorStr: vehiclePlateColor,
              licensePlateNumber: vehiclePlateNumber,
              vehicleColorStr: vehicleColor,
            } = item;
            return {
              vehicleId,
              brand,
              seatNumber,
              vehiclePlateColor,
              vehiclePlateNumber,
              vehicleColor,
            };
          });

          this.$nextTick(() => {
            let item = this.carList[0];
            item && (this.base = Object.assign({}, this.base, item));
          });
        }
      });
    },
    //车辆选择
    checkCar(vehicleId) {
      let ps = this.carList.find((item) => item.vehicleId === vehicleId);
      if (ps) {
        this.base = Object.assign({}, this.base, ps);
      }
    },
    //取消取车
    handleCancel() {
      this.base = {};
      this.carInfo = {};
      this.carList = {};
      this.visible = false;
    },
    //确定取车
    handleSubmit() {
      let {
        id: rentalOrderId,
        vehicleId,
        startDriveDistance,
        companyId: vehicleCompanyId,
        carServiceProviderName: vehicleCompanyName,
        brand: vehicleBrand,
        vehiclePlateColor,
        vehiclePlateNumber,
        vehicleColor,
      } = this.base;
      let parms = {
        rentalOrderId,
        vehicleId,
        startDriveDistance,
        vehicleCompanyId,
        vehicleCompanyName,
        vehicleBrand,
        vehiclePlateColor,
        vehiclePlateNumber,
        vehicleColor,
      };
      if (!startDriveDistance) {
        this.$message.error("请输入取车里程表值！");
        return;
      }

      this.loading = true;
      takeCarPickUp(parms)
        .then((res) => {
          if (res.code === "SUCCESS") {
            this.$emit("finsh");
            this.handleCancel();
            return;
          }
          this.$message.error(res.msg);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cardTop {
  text-align: center;
  img {
    width: 200px;
    height: 120px;
  }
}
.mt20 {
  margin-top: 20px;
}
.pdtb10 {
  padding: 10px 0;
}
.contentBox {
  background: #fafbff;
  padding: 10px 0;
}
.indent10 {
  text-indent: 10px;
  font-weight: 600;
  color: #333;
}
.infoBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  & > div {
    width: 33.3%;
    flex-shrink: 0;
    text-align: center;
    color: #999;
    padding: 20px 0;
    box-sizing: border-box;
    .sing {
      color: #333;
      font-weight: 500;
      margin: 5px 0;
    }
  }

  .borderR {
    border-right: 1px solid #f2f2f2;
  }

  .borderT {
    border-top: 1px solid #f2f2f2;
  }
}
.insertBox {
  display: flex;
  align-items: center;
  & > label {
    width: 120px;
    text-align: right;
  }
  & > div {
    width: 260px;
  }
  .number-text-l {
    /deep/ input {
      text-align: left !important;
    }
  }
}
.text-r {
  text-align: right;
}
</style>
